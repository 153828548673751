import type { Environment } from '@dextools/core';
import type { ApiUrlKey } from './environment.local';
import { appTitle, APP_VERSION, chains, externalLink, getAdminPaths } from './environment.local';

const domain = 'www.dextools.io';
const appScope = '';
const host = `https://${domain}`;
const backUrl = `${host}/back`;
const shared = `${host}/shared`;
const cfProxyUrl = `${host}/cz`;

const appTitleStage = appTitle;
const appUrl = `${host}${appScope}`;
export const blacklistedWindowNames = ['refreshingWindow'];
const coreApiURL = `https://core-api-dev.dextools.io`;

const apiUrls: Record<ApiUrlKey, string> = {} as Record<ApiUrlKey, string>;

for (const current of chains) {
  switch (current) {
    case 'bnb': {
      apiUrls[`api_${current}_url`] = `${host}/chain-bsc/api`;
      break;
    }
    case 'oktc': {
      apiUrls[`api_${current}_url`] = `${host}/chain-oec/api`;
      break;
    }
    case 'multiversx': {
      apiUrls[`api_${current}_url`] = `${host}/chain-elrond/api`;
      break;
    }
    case 'dis': {
      apiUrls[`api_${current}_url`] = `${host}/chain-ethf/api`;
      break;
    }
    case 'viction': {
      apiUrls[`api_${current}_url`] = `${host}/chain-tomo/api`;
      break;
    }
    default: {
      apiUrls[`api_${current}_url`] = `${host}/chain-${current}/api`;
    }
  }
}

class EnvironmentImpl implements Environment {
  public appTitle = appTitleStage;
  public externalLink = externalLink;
  public allowAdmin = true;
  public app_scope = appScope;
  public appUrl = `${appUrl}`;
  public host = `${host}`;
  public assets_url = `${appUrl}/assets`;
  public appLogo = `${appUrl}/assets/img/logo/dextools_logo_header_125.png`;
  public apiUrls = { ...apiUrls };
  public back_url = `${backUrl}`;
  public meta_url = `${host}/meta`;
  public ws_url = 'wss://ws.dextools.io/';
  public paths = getAdminPaths(apiUrls);
  public production = true;
  public whitelistedUrls = [] as string[];
  public siteId = '';
  public appVersion = APP_VERSION;
  public shared_url = `${shared}`;
  public cf_proxy_url = cfProxyUrl;
  public local_prod_url = null;
  public core_api_url = coreApiURL;
}

export const environment = new EnvironmentImpl();
